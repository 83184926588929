<template lang="pug">
div.absolute.flex.flex-col.inset-y-0.overflow-auto.scrollbar--hidden(class='max-w-[368px] transition-all w-full bg-white border-l py-6 px-4' :class='{ "right-0": show, "-right-[368px]": !show }' v-show='show')
  // Back & Close popup
  .flex.items-center.justify-between
    // back
    .flex.items-center
      Icon.w-5.h-5.shrink-0.cursor-pointer.mr-2(name='lucide:arrow-left' @click='closeRequired')
      div.text-xs.font-medium
        span(class='text-[#9C9C9C]') Share / 
        span(class='') {{ account?.username }}
    // close
    Icon.w-5.h-5.shrink-0.cursor-pointer.mr-2(name='lucide:x' @click='closeRequired')
  // Access
  .text-lg.font-medium.mt-4 Access
  // NDA 
  .nda.mt-2.mb-6(class='text-[#94A3B8]')
    .flex.items-center.justify-between
      .text-sm.font-medium Require an NDA
      .rounded-full.bg-primary.text-white(class='py-0.5 px-2 text-[10px] leading-4') Enterprise
    .mt-2.text-xs
      span Require the viewer to electronically sign a 
      span.underline non-disclosure agreement
      span before viewing this Journey.
  // Required to view
  div(v-for='content in requiredToViews')
    .w-full(class='h-[1px] bg-[#E5E7EB]')
    EditLayoutRequiredToView.my-6(:content='content')
  // line
  .w-full(class='h-[1px] bg-[#E5E7EB] border-b')
  //password protect
  div.my-6
    .email-required(class='')
      .flex.items-center.justify-between
        .text-sm.font-medium Password protect
        Switch(id="airplane-mode" v-model:checked='requiredPassword')
    .password_protect.mt-4(class='' v-if='requiredPassword')
      Input#password.text-sm.transition-all(
        v-model='password'
        name='password'
        type='password'
        placeholder='Type an email address and press enter'
        class='focus-visible:ring-0 focus-visible:ring-offset-0 focus-visible:shadow-[inset_0_0_0_1px_#8A2BE2]'
      )
  // line
  .w-full(class='h-[1px] bg-[#E5E7EB] border-b').mb-6
  // Expiration date
  .flex.items-center.justify-between.mb-6(class='text-[#94A3B8]')
    .text-sm.font-medium Expiration date
    .rounded-full.bg-primary.text-white(class='py-0.5 px-2 text-[10px] leading-4') Enterprise
  // line
  .w-full(class='h-[1px] bg-[#E5E7EB] border-b').mb-6
  // Require custom fields
  .nda.mt-2(class='text-[#94A3B8]')
    .flex.items-center.justify-between
      .text-sm.font-medium Require custom fields
      .rounded-full.bg-primary.text-white(class='py-0.5 px-2 text-[10px] leading-4') Enterprise
    .mt-2.text-xs Require custom fields like visitor name and company name

</template>

<script setup lang="ts">
import { Switch } from '@/components/ui/switch'
import { Input } from '@/components/ui/input'
import { storeToRefs } from 'pinia'

// =========== BINDING ACCOUNT NAME =============
const { account } = storeToRefs(useUserStore())

const requiredToViews = ref([
  {
    title: 'Require email to view',
    desc: 'Control whitelists, blacklists, and whether users need to submit an email address to view',
    verified_title: 'Require emails to be verified',
    verified_desc:
      'Viewers must verify their email by entering an access code sent to their email',
  },
  {
    title: 'Require wallet to view',
    desc: 'Control whitelists, blacklists, and whether users need to submit an email address to view',
    verified_title: 'Require wallet to be verified',
    verified_desc:
      'Viewers must verify their wallet by sharing the public anonymous credentials',
  },
])

const { show, closeRequired } = useRequiredField()

const requiredPassword = ref(false)
const password = ref('')
</script>

<style scoped></style>
